<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">平台配置</div>
        <el-button type="primary" @click="handleEdit()">新增平台</el-button>
      </div>
      <div class="search">
        <el-input v-model="name" type="text" placeholder="请输入平台名称" clearable></el-input>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>

    <el-table :data="tableData.items" v-loading="loading">
      <el-table-column prop="name" label="平台名称"></el-table-column>
      <el-table-column label="平台logo">
        <template #default="scope">
          <img :src="scope.row.logo_img?.url" alt="平台logo" />
        </template>
      </el-table-column>
      <el-table-column prop="app_id" label="平台id"></el-table-column>
      <el-table-column prop="title" label="平台站点名称"></el-table-column>
      <el-table-column prop="hot_line" label="客服热线"></el-table-column>
      <el-table-column prop="tag" label="资源标识"></el-table-column>
      <el-table-column label="登录类型">
        <template #default="scope">
          {{ ['', '授权', '验证码'][scope.row.login_type] }}
        </template>
      </el-table-column>
      <el-table-column prop="class_name" label="授权扩展类名"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template #default="scope">
          <el-button plain size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button plain size="small" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>

    <!-- 新增/编辑对话框 -->
    <edit-app ref="editApp" :show="dialogVisible" @confirm="pageEmit(1)"></edit-app>
  </div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance } from 'vue'
import appModel from '@/model/application'
import commonHandle from '@/hooks/useCommon'
import editApp from './components/edit-app.vue'

export default {
  components: {
    editApp,
  },
  setup(props) {
    const name = ref()
    const ctx = getCurrentInstance()

    // 获取平台列表
    const getData = async pageQuery => {
      loading.value = true
      const res = await appModel.getApplicationList({
        page: pageQuery?.page || pageParam.currentPage - 1,
        count: pageQuery?.count || pageParam.count,
        name: name.value,
      })
      loading.value = false
      tableData.value = res
    }

    // 新增/编辑
    const handleEdit = (item = {}) => {
      ctx.refs.editApp.init(item)
    }

    // 双击行
    const rowDoubleClick = row => {
      handleEdit(row)
    }

    // 删除
    const deleteFun = async id => {
      let res
      res = await appModel.deleteApplication(id)
      return res
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    onMounted(() => {
      getData()
    })

    return {
      loading,
      pageParam,
      name,
      tableData,
      handleEdit,
      handleDelete,
      pageEmit,
      rowDoubleClick,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
</style>
